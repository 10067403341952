import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useMemo } from "react";
import superjson from "superjson";
import { createTRPCReact, httpBatchLink, loggerLink, TRPCClientError } from "@trpc/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Cookies from "js-cookie";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import * as Sentry from "@sentry/remix";
import { useAuth } from "@clerk/remix";
import type { AppRouter } from "~/routes/trpc.$trpc/app-router";
import { env } from "~/utils/frontend-env";
import { isDefined } from "~/utils/typeguard";
import { useToastWarning } from "~/ui/tracked/tracked/warning";
import { TokenExpiredCode } from "~/routes/api+/auth/verify-clerk-sesion-token-errors";

const mapTrpcErrorToMsgs = {
  BAD_REQUEST: "The request to the server was invalid.",
  UNAUTHORIZED: "Unauthorized to make this request.",
  FORBIDDEN: "The request was not allowed by the server.",
  NOT_FOUND: "Not found.",
  TIMEOUT: "The request timed out.",
  CONFLICT: "The request conflicted with another request.",
  PRECONDITION_FAILED: "The request failed a precondition.",
  PAYLOAD_TOO_LARGE: "The request payload was too large.",
  METHOD_NOT_SUPPORTED: "The request HTTP method is not supported.",
  TOO_MANY_REQUESTS: "Too many requests.",
  CLIENT_CLOSED_REQUEST: "The client closed the request.",
  INTERNAL_SERVER_ERROR: "An internal server error occurred.",
} as const;

function isJwtExpiredError(trpcError: TRPCClientError<AppRouter>): boolean {
  return trpcError.shape?.message === TokenExpiredCode;
}

function defineUserFacingMessage(trpcError: TRPCClientError<AppRouter>): string {
  const trpcMessage = trpcError.shape?.message;
  let userFacingMessage: string;
  if (isDefined(trpcMessage)) {
    userFacingMessage = trpcMessage;
  } else if (isDefined(trpcError.data?.code)) {
    userFacingMessage = mapTrpcErrorToMsgs[trpcError.data?.code as keyof typeof mapTrpcErrorToMsgs];
  } else {
    userFacingMessage = "An error occurred while making the request.";
  }

  return userFacingMessage;
}

function useShouldRetry(): (failureCount: number, error: unknown) => boolean {
  return (failureCount: number, error: unknown): boolean => {
    if (!(error instanceof TRPCClientError)) {
      return false;
    }

    const trpcError = error as TRPCClientError<AppRouter>;

    const shouldRetry = failureCount < 1 && isJwtExpiredError(trpcError);

    return shouldRetry;
  };
}

function useHandleTrpcError(): (error: unknown) => void {
  const toastWarning = useToastWarning();
  const auth = useAuth();

  return (error: unknown) => {
    if (!(error instanceof TRPCClientError)) {
      return;
    }

    const trpcError = error as TRPCClientError<AppRouter>;
    const userFacingMessage = defineUserFacingMessage(trpcError);

    if (trpcError.data?.code === "BAD_REQUEST") {
      toastWarning(userFacingMessage, error);
    } else if (trpcError.data?.code === "UNAUTHORIZED") {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "TRPC reports user is unauthorized.",
        data: auth,
        level: "warning",
      });
    }
  };
}

export function TrpcProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const handleTrpcError = useHandleTrpcError();
  const shouldRetry = useShouldRetry();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: shouldRetry,
          },
          mutations: {
            retry: shouldRetry,
          },
        },
        queryCache: new QueryCache({
          onError: handleTrpcError,
        }),
        mutationCache: new MutationCache({
          onError: handleTrpcError,
        }),
      }),
    [handleTrpcError, shouldRetry],
  );
  const trpcClient = useMemo(
    () =>
      trpcQuery.createClient({
        transformer: superjson,
        links: [
          loggerLink({
            enabled: (opts) =>
              env.REACT_APP_ENV === "dev" ||
              env.REACT_APP_ENV === "stg" ||
              (opts.direction === "down" && opts.result instanceof Error),
          }),
          httpBatchLink({
            url: "/trpc",
            headers() {
              return {
                authorization: Cookies.get("__session"),
              };
            },
          }),
        ],
      }),
    [],
  );
  return (
    <trpcQuery.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </trpcQuery.Provider>
  );
}

export const trpcQuery = createTRPCReact<AppRouter>();

/**
 * Inference helpers for input types
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helpers for output types
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>;
