import { useAuth } from "@clerk/remix";
import * as Sentry from "@sentry/remix";
import { trpcQuery } from "~/providers/trpc";

export function useIsFreeUser(): boolean {
  const { isSignedIn } = useAuth();

  // Attempt to debug this issue where isFreeUser is triggered even when user is not signed in.
  // https://double-automation.sentry.io/issues/4233816215
  Sentry.addBreadcrumb({
    category: "useIsFreeUser",
    message: "useIsFreeUser",
    level: "debug",
    data: {
      isSignedIn,
    },
  });

  const { data: isFreeUser = true } = trpcQuery.user.isFreeUser.useQuery(undefined, {
    enabled: isSignedIn,
  });

  if (!isSignedIn) {
    return true;
  }

  return isFreeUser;
}
