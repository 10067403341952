import type { ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
// eslint-disable-next-line no-restricted-imports -- This is a wrapper around Chakra's Button component.
import { Button as ChakraButton } from "@chakra-ui/react";
import { useTrack } from "../use-track";
import type { TrackProps } from "../types";

export interface ButtonProps extends ChakraButtonProps, TrackProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function Button({
  eventName,
  eventProperties,
  onClick,
  ...restProps
}: ButtonProps): JSX.Element {
  const track = useTrack();
  return (
    <ChakraButton
      {...restProps}
      onClick={(e): void => {
        track(eventName, eventProperties);
        onClick?.(e);
      }}
    />
  );
}
