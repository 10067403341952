// import { H } from "@highlight-run/remix/client";
import mixpanel from "mixpanel-browser";
import type { TrackProps } from "./types";
import { enableMixpanel } from "~/constants";
import { useIsFreeUser } from "~/utils/use-is-free-user";
import { useIsImpersonatedSession } from "~/utils/use-is-impersonated-session";

/**
 * Interface for tracking product analytics events.
 *
 * @param eventName - The name of the event to track.
 * @param properties - A dictionary of properties to attach to the event.
 *
 * @example
 * ```
 * const track = useTrack();
 * track("sign-up", { plan: "free" });
 * ```
 */
export function useTrack(): (
  eventName: TrackProps["eventName"],
  properties?: TrackProps["eventProperties"]
) => void {
  const isImpersonatedSession = useIsImpersonatedSession();
  const isFreeUser = useIsFreeUser();

  return (eventName: TrackProps["eventName"], properties?: TrackProps["eventProperties"]): void => {
    if (isImpersonatedSession) {
      return;
    }

    const propertiesWithIsPaid = {
      isPaid: !isFreeUser,
      ...properties,
    };
    // if (enableHighlight) {
    //   H.track(eventName, propertiesWithIsPaid as { [key: string]: string | number | boolean });
    // }
    if (enableMixpanel) {
      mixpanel.track(eventName, propertiesWithIsPaid);
    }
  };
}
