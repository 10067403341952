import { useToast } from "@chakra-ui/react";

export function useToastWarning(): (userFacingMessage: string, fullWarning?: unknown) => void {
  const toast = useToast();

  /**
   * Report an warning to the user via a Toast and to the console.
   *
   * @param userFacingMessage - A message to show the user.
   * @param fullWarning - The full warning object to log to the console.
   */
  return (userFacingMessage: string, fullWarning?: unknown) => {
    toast({
      title: "Warning",
      description: userFacingMessage,
      status: "warning",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    console.warn(fullWarning ?? userFacingMessage);
  };
}
